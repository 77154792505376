<template>
	<div>
		<b-row>
			<b-col cols="6">{{$t('general.vk_short')}}</b-col>
			<b-col cols="6">{{$t('detailPanel.vkGlasses')}}</b-col>
		</b-row>
		<b-row class="supplierRow mb-3">
			<b-col cols="6">
				<div>
								<span class="smallText">{{$t('general.netPrice')}}: </span>
								<span class="currency">{{ getNetPrice(selectedWine.attributes.sellingPrice) }} {{currency}}</span>
								<span class="smallText">{{$t('general.grossPrice')}}: </span>
								<span class="currency">{{ selectedWine.attributes.sellingPrice }} {{currency}}</span>
				</div>
			</b-col>
			<b-col cols="6">
				<div>
						<span class="smallText">{{$t('general.netPrice')}}: </span>
						<span class="currency">{{ getNetPrice(selectedWine.attributes.glassPrice) }} {{currency}}</span>
						<span class="smallText">{{$t('general.grossPrice')}}: </span>
						<span class="currency">{{ selectedWine.attributes.glassPrice }} {{currency}}</span>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import { getNetPrice } from '@/utils/functions'

export default {
    name: 'SellingPrices',
    props: {
      selectedWine: Object,
			currency: String
    },
		methods: {
			getNetPrice,
		}
}

</script>