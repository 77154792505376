<template>
	<div>
		<b-row class="mt-4">
			<b-col cols="12">{{$t('general.rating')}}</b-col>
		</b-row>
		<div class="ratings">
			<div class="rating" v-for="rating in ratings" v-bind:key="rating.id">
				<span class="ratingTitle">{{ rating.ratingAgency.data.attributes.title }}:</span>
				<span class="ratingValue">
						<span class="star" v-if="rating.ratingAgency.data.attributes.ratingType == 'stars'"><span :class="{ 'filled': n <= rating.value }" v-for="n in rating.ratingAgency.data.attributes.max" class="starIcon" v-bind:key="n"></span></span>
						<span class="points" v-if="rating.ratingAgency.data.attributes.ratingType == 'points'">{{rating.value}} {{rating.ratingAgency.data.attributes.ratingType}}</span>
				</span>
			</div>
		</div>
	</div>
</template>

<script>

export default {
    name: 'Ratings',
    props: {
      ratings: Array,
    },
}

</script>