<template>
	<div>
		<b-row>
				<b-col cols="6">{{$t('general.vkGross')}} {{currency}}</b-col>
				<b-col cols="6">
						<i18n path="general.vkGrossGlass" >
								<template v-slot:currency>
										{{currency}}
								</template>
						</i18n>
				</b-col>
		</b-row>
		<b-row>
			<b-col cols="6"><input min="0" type="number" v-model="sellingPrice" @input="setTempSellingPrice"></b-col>
			<b-col cols="6"><input min="0" type="number" v-model="glassPrice"></b-col>
		</b-row>
	</div>
</template>

<script>

export default {
    name: 'SellingPrices',
    props: {
      selectedWine: Object,
			currency: String
    },
		data () {
			return {
				sellingPrice: this.selectedWine.attributes.sellingPrice,
				glassPrice: this.selectedWine.attributes.glassPrice,
			}
		},
		methods: {
			setTempSellingPrice() {
				this.$emit('tempSellingPrice',parseFloat(this.sellingPrice))
			}
		}
}

</script>