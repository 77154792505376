<template>
  <b-dropdown :text="yearDropdownText(shownYear)" class="yearsDropdown">
    <b-dropdown-item :value="0" @click="emitChangedYear(0)">
        n/a
    </b-dropdown-item>
    <b-dropdown-item v-for="year in yearsArray" :key="year.value" :value="year.value" @click="emitChangedYear(year.value)">
      {{year.text}}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
  export default {
    name: 'YearDropdown',
    props: {
      selectedYear: Number
    },
    created () {
      var actualYear = new Date().getFullYear();
      for (let i = 0; i < 101; i++) {
          var option = {
              value: actualYear - i,
              text: actualYear - i
          }
          this.yearsArray.push(option)
      }
      this.shownYear = this.selectedYear
    },
    data() {
        return {
          yearsArray: [],
          shownYear: 0
        }
    }, 
    methods: {
      yearDropdownText(year) {
        if (year === 0) {
            return 'n/a'
        } else {
            return year+''
        }
      },
      emitChangedYear(year) {
        this.shownYear = year
        this.$emit('year-changed', year);
      }

    }
  }
</script>
<style scoped>
</style>

