<template>
	<div>
		<h2>{{$t('detailPanel.headlineHistory')}}</h2>
		<div class="box venueWineHistoryContainer">
				<ul class="venueWineHistory">
						<li v-for="item in historyMessages" :key="item.id">
								<span class="date">{{ item.date }}</span> <span class="user">{{ item.user }}</span>
								<span class="message">{{ item.message }}</span>
						</li>
				</ul>
		</div>
	</div>
</template>

<script>

import DataService from '@/services/StrapiService'
import DateTimeService from '@/services/DateTimeService'

export default {
		name: 'History',
		props: {
			selectedWine: Object,
		},
		created() {
      // this function is only used when the first overlay is created
      this.loadWineHistory()
		},
		data () {
			return {
				historyMessages: [],
			}
		},
		methods: {
			loadWineHistory() {
					const historyType = this.getHistoryType()
          this.historyMessages = []
					DataService.findVenueWineHistory(this.selectedWine.id).then((response) => {
							// this.historyMessages = response['data'].data.reverse()
							var historyArray = response["data"].data.reverse();
							var historyMessages = [];
							for (const history of historyArray) {
									if (history.venueWine == null || Object.keys(history.venueWine).length === 0) { //skip when history entry is kaputt
											continue;
									}
									var msg = "";
									const newDate = new Date(history.createdAt);
									const parsedDatetime = DateTimeService.dateToFormattedstring(newDate, true, this.$i18n.locale);
									let date = parsedDatetime + " - ";
									switch (history.type) {
											// Wein gelöscht  
											case historyType.delete:
													msg += this.$t("detailPanel.historyMessages.wineDeleted", { wineTitle: history.venueWine.title });
													break;
											// Wein angelegt  
											case historyType.create:
													msg += this.$t("detailPanel.historyMessages.wineCreated", { wineTitle: history.venueWine.title });
													break;
											// Anzahl geändert  
											case historyType.change_amount:
													if (parseInt(history.before) > parseInt(history.after)) {
															msg += this.$t("detailPanel.historyMessages.reducedAmountBottles", { before: history.before, after: history.after });
													}
													else {
															msg += this.$t("detailPanel.historyMessages.increasedAmountBottles", { before: history.before, after: history.after });
													}
													break;
											// Wein umgezogen 
											case historyType.move_amount:
													if (history.venueWine.glassMode == true && history.newVenue == null) {
															if (Math.abs(history.beforeAfterDiff) > 1) {
																	msg += this.$t("detailPanel.historyMessages.bottlesConvertedToGlasses", { bottleCount: Math.abs(history.beforeAfterDiff) });
															}
															else {
																	msg += this.$t("detailPanel.historyMessages.bottleConvertedToGlasses", { bottleCount: Math.abs(history.beforeAfterDiff) });
															}
													}
													else {
															var newVenueTitle = "";
															if (history.newVenue) {
																	newVenueTitle = `in Location ${history.newVenue.title}`;
															}
															if (Math.abs(history.beforeAfterDiff) > 1) {
																	msg += this.$t("detailPanel.historyMessages.moevedWines", { bottleCount: Math.abs(history.beforeAfterDiff), venueName: newVenueTitle });
															}
															else {
																	msg += this.$t("detailPanel.historyMessages.moevedWine", { bottleCount: Math.abs(history.beforeAfterDiff), venueName: newVenueTitle });
															}
													}
													break;
											// Anzahl Glässer geändert  
											case historyType.change_amount_glasses:
													if (history.beforeAfterDiff < 0) {
															msg += this.$t("detailPanel.historyMessages.reducedAmountGlasses", { amount: Math.abs(history.beforeAfterDiff) });
													}
													else {
															continue;
													}
													break;
											// Glässer gelöscht 
											case historyType.move_amount_glasses:
													if (history.beforeAfterDiff < 0) {
															msg += this.$t("detailPanel.historyMessages.deletedGlasses", { amount: Math.abs(history.beforeAfterDiff) });
													}
													else {
															continue;
													}
													break;
											// Preis verändert
											case historyType.change_price:
													if (history.fieldName === "purchasePriceAvg") {
															if (parseFloat(history.before) > parseFloat(history.after)) {
																	msg += this.$t("detailPanel.historyMessages.reducedPurchasePrice", { before: history.before, after: history.after });
															}
															else {
																	msg += this.$t("detailPanel.historyMessages.increasedPurchasePrice", { before: history.before, after: history.after });
															}
													}
													if (history.fieldName === "sellingPrice") {
															if (parseFloat(history.before) > parseFloat(history.after)) {
																	msg += this.$t("detailPanel.historyMessages.reducedSellingPrice", { before: history.before, after: history.after });
															}
															else {
																	msg += this.$t("detailPanel.historyMessages.increasedSellingPrice", { before: history.before, after: history.after });
															}
													}
													if (history.fieldName === "glassPrice") {
															if (parseFloat(history.before) > parseFloat(history.after)) {
																	msg += this.$t("detailPanel.historyMessages.reducedSellingPriceGlasses", { before: history.before, after: history.after });
															}
															else {
																	msg += this.$t("detailPanel.historyMessages.increasedSellingPriceGlasses", { before: history.before, after: history.after });
															}
													}
													break;
											// Einkaufpreis angelegt
											case historyType.create_purchase_price:
													msg += this.$t("detailPanel.historyMessages.addedSuplier");
													break;
											// Einkaufpreis gelöscht
											case historyType.delete_purchase_price:
													msg += this.$t("detailPanel.historyMessages.deletedSuplier");
													break;
											// Feld verändert
											case historyType.change:
													var fieldString = this.$t("general.shelf");
													msg += this.$t("detailPanel.historyMessages.changedField", { fieldName: fieldString, before: history.before, after: history.after });
													break;
											// Notiz geändert
											case historyType.comments:
													msg += this.$t("detailPanel.historyMessages.changedTastingNote");
													break;
											// Glasweise verändert
											case historyType.glass_mode:
													if (history.after === "true") {
															msg += this.$t("detailPanel.historyMessages.activatedGlasswise");
													}
													else {
															msg += this.$t("detailPanel.historyMessages.deactivatedGlasswise");
													}
													break;
											// Bezugsquelle geändert
											case historyType.wineSupplier:
													msg += this.$t("detailPanel.historyMessages.changedSuplier");
													break;
											// Wein reduziert
											case historyType.change_without_revenue:
													if (parseInt(history.before) > parseInt(history.after)) {
														msg += this.$t("detailPanel.historyMessages.deletedWineWithoutRevenue", { amount: Math.abs(history.beforeAfterDiff) });
													}
													else {
														msg += this.$t("detailPanel.historyMessages.increasedAmountBottlesWithoutRevenue", { amount: Math.abs(history.beforeAfterDiff) });
													}
													break;
											case historyType.none:
													break;
											default:
									}
									let user = this.$t('general.deletedEmployee')
									if(history.changedByUser != null) {
										user = history.changedByUser.username;
									}
									historyMessages.push({
											message: msg,
											date: date,
											user: user,
									});
							} //eo for loop
							this.historyMessages = historyMessages;
					}).catch((err) => {
							console.log(err);
					});
			},
			getHistoryType() {
				return {
					delete: "delete",
					create: "create",
					move: "move",
					change_amount: "change_amount",
					change_without_revenue: "change_without_revenue",
					move_amount: "move_amount",
					change_amount_glasses: "change_amount_glasses",
					move_amount_glasses: "move_amount_glasses",
					change_price: "change_price",
					create_purchase_price: "create_purchase_price",
					delete_purchase_price: "delete_purchase_price",
					change: "change",
					import: "import",
					wineSupplier: "wineSupplier",
					comments: "comments",
					glass_mode: "glass_mode",
					discard_amount: "discard_amount",
					discard_amount_glasses: "discard_amount_glasses",
					favorite: "favorite",
					none: "none"
				}
			}
		},
}

</script>